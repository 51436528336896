import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from './pages/home';
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import Profile from './pages/profile';
import ForgotPasswordPage from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import VerifyUser from './pages/verify-user';
import SendVerification from './pages/send-verification';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/register" element={<RegisterPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route
            path="/reset-password"
            element={<ResetPassword/>}
          ></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/verify-user" element={<VerifyUser/>}></Route>
          <Route path="/send-verification-code" element={<SendVerification />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
