import { Col, Container, Row } from "reactstrap";
import NavbarComponent from "../navbar/navbar.component";
import EditableInput from "../editable/editable_input";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../miscellaneous";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [relExperience, setRelExperience] = useState("");
  const [experience, setExperience] = useState("");
  const [company, setCompany] = useState("");
  const [salary, setSalary] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [qualification, setQualification] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState({});

  const [loading, setLoading] = useState(true); // Add loading state

  const setProfileDetails = (profileDetails) => {
    setFirstName(profileDetails.first_name);
    setMiddleName(profileDetails.middle_name);
    setLastName(profileDetails.last_name);
    setEmail(profileDetails.email);
    setPhone(profileDetails.phone);
    setRelExperience(profileDetails.relevant_experience);
    setExperience(profileDetails.experience);
    setCompany(profileDetails.company);
    setNoticePeriod(profileDetails.notice_period);
    setSalary(profileDetails.ctc);
    setQualification(profileDetails.qualification);
  };

  const AddOrUpdateProfile = async () => {
    console.log(`inside add or update profile ${firstName}`);
    let payload = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      email: email,
      phone: phone,
      qualification: qualification,
      relevant_experience: relExperience,
      experience: experience,
      company: company,
      notice_period: noticePeriod,
      ctc: salary,
    };
    try {
      // Make a POST request using Axios
      const response = await axios.post(
        `${BASE_URL}api/add-profile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setErrorList({});
      // Handle response
      console.log(response.status);
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login");
      }
      console.log(error.response);
      // Handle error
      setErrorList(error.response.data);
    }
  };

  useEffect(() => {
    const FetchDetails = async (token) => {
      try {
        const profileDetails = await axios.get(
          `${BASE_URL}api/view-profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProfileDetails(profileDetails.data);
        setLoading(false);
        const authInfo = await axios.get(`${BASE_URL}api/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        let login_status = authInfo.data.login_status;
        setUser(authInfo.data.user);
        if (login_status === "logged_in") {
          setIsLoggedIn(true);
        }
      } catch (error) {
        if(error.response.status === 401){
          navigate("/login");
        }
      } finally {
        // console.log("done");
      }
    };
    FetchDetails(localStorage.getItem("token"));
  }, [navigate]);
  if (loading) {
    return <div>Loading...</div>; // Render loading indicator while data is being fetched
  }
  return (
    <main>
      <NavbarComponent isLoggedIn={isLoggedIn} username={user} />
      <Container className="mt-5">
        <h4>Personal Details</h4>
        <hr />
        <div className="input-row">
          <Row>
            <Col lg={4} md={4} sm={12}>
              <EditableInput
                type="text"
                label="First Name"
                placeholder="First Name"
                copy={firstName}
                setVal={setFirstName}
                val={firstName}
              />
              <span className="ms-5 text-danger">
                {errorList["first_name"]}
              </span>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <EditableInput
                type="text"
                label="Middle Name"
                placeholder="Middle Name"
                copy={middleName}
                setVal={setMiddleName}
                val={middleName}
              />
              <span className="ms-5 text-danger">
                {errorList["middle_name"]}
              </span>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <EditableInput
                label="Last Name"
                placeholder="Last Name"
                val={lastName}
                copy={lastName}
                setVal={setLastName}
              />
              <span className="ms-5 text-danger">{errorList["last_name"]}</span>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <EditableInput
              type="email"
              label="Email"
              val={email}
              placeholder="Email"
              copy={email}
              setVal={setEmail}
            />
            <span className="ms-5 text-danger">{errorList["email"]}</span>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <EditableInput
              type="phone"
              label="Phone"
              val={phone}
              placeholder="Phone"
              copy={phone}
              setVal={setPhone}
            />
            <span className="ms-5 text-danger">{errorList["phone"]}</span>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <EditableInput
              type="text"
              label="Qualification"
              val={qualification}
              placeholder="Qualification"
              copy={qualification}
              setVal={setQualification}
            />
            <span className="ms-5 text-danger">{errorList["phone"]}</span>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 mb-5">
        <h4 className="pt-5">Professional Details</h4>
        <hr />
        <div className="input-row">
          <Row>
            <Col lg={4} md={4} sm={12}>
              <EditableInput
                type="experience"
                label="Experience in years"
                val={relExperience}
                placeholder="Relevant Experience in years"
                copy={relExperience}
                setVal={setRelExperience}
              />
              <span className="ms-5 text-danger">
                {errorList["relevant_experience"]}
              </span>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <EditableInput
                type="text"
                label="Experience in years"
                val={experience}
                placeholder="Experience in years"
                copy={experience}
                setVal={setExperience}
              />
              <span className="ms-5 text-danger">
                {errorList["experience"]}
              </span>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <EditableInput
                type="text"
                label="Company"
                val={company}
                placeholder="Company"
                copy={company}
                setVal={setCompany}
              />
              <span className="ms-5 text-danger">{errorList["company"]}</span>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <EditableInput
              type="phone"
              label="Notice Period in days"
              val={noticePeriod}
              placeholder="Notice Period"
              copy={noticePeriod}
              setVal={setNoticePeriod}
            />
            <span className="ms-5 text-danger">
              {errorList["notice_period"]}
            </span>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <EditableInput
              type="text"
              label="Salary(CTC) in LPA"
              val={salary}
              placeholder="Salary(CTC per year)."
              copy={salary}
              setVal={setSalary}
            />
            <span className="ms-5 text-danger">{errorList["ctc"]}</span>
          </Col>
        </Row>
        <hr />
        <button
          className="btn btn-primary rounded-0 p-3 float-end mb-5"
          onClick={AddOrUpdateProfile}
        >
          Add/Update Your profile
        </button>
      </Container>
    </main>
  );
};
export default Profile;
