import { Button, Input } from "reactstrap";
import "./main-card.css";
import { FaCopy, FaEnvelopeOpen, FaPaperPlane, FaPaste, FaX } from "react-icons/fa6";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../miscellaneous";


const MainCard = ({ username = "" }) => {
  const [recipient, setRecipient] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [matter, setMatter] = useState("");
  const [subject, setSubject] = useState("Job Application");
  const [showLoading, setShowLoading] = useState(false)
  const navigate = useNavigate();
  const fetchData = async () => {
    setShowLoading(true)
    try {
      const response = await axios.post(
        `${BASE_URL}api/get-job-description`,
        { recipient_email: recipient, matter: matter },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let mtr = response.data.response;
      setSubject(`Job Application: ${username}`);
      setMatter(mtr);
    } catch (error) {      
      if (error.response.status === 401){
        navigate("login");
      }
        if (error.response && error.response.data) {
          console.log(error.response.data);
          setErrorStatus(error.response.data.response);
        }
    } finally {
      console.log("done");
      setShowLoading(false)
    }
  };

  const handleClick = () => {
    fetchData();
  };

  const handleMatterChange = (e) => {
    setMatter(e.target.value);
  };

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setRecipient(text);
      })
      .catch((err) => {
        console.error("Failed to read clipboard contents: ", err);
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(matter);
  };

  const handleSendMailButton = () => {};

  const handleClear = () => {
    setMatter("");
  };

  const handleRecipientInput = (e) => {
    setRecipient(e.target.value);
  };

  function encodeMailtoBody(body) {
    return encodeURIComponent(body).replace(/%0A/g, "%0D%0A");
  }

  
  return (
    <div className="card main-card mt-5 rounded-0">
      <img className="img-fluid" src="job-search.jpg" alt="banner" />
      <div className="alert-danger text-center text-danger">{errorStatus}</div>
      <div className="card-body p-4">
        <div className="mt-3 mb-3">
          <label className="small-font">Email ID of recipient</label>
          <div className="jd-div">
            <Input
              type="email"
              placeholder="Email ID of recipient"
              className="rounded-0 new-ip"
              onChange={handleRecipientInput}
              autoComplete="on"
              value={recipient}
            />
            <button className="jd-paste-button btn" onClick={handlePaste}>
              <FaPaste />
            </button>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <label className="small-font">Job Description</label>
          <div className="jd-div">
            <textarea
              disabled={showLoading}
              placeholder="Enter job description or job title or atleast company name to generate the job application email"
              className="form-control jd-text-area rounded-0 new-ip"
              value={matter}
              onChange={handleMatterChange}
            ></textarea>
            <button className="jd-paste-button btn" onClick={handleCopy}>
              <FaCopy />
            </button>
            <button className="jd-x-button btn" onClick={handleClear}>
              <FaX />
            </button>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <Button
            className="btn btn-main w-100 rounded-0"
            onClick={handleClick}
          >
            {showLoading ? (
              <div>
                Generating Email
                <img
                  src="circle-1700_128.gif"
                  className="mb-1 ms-2 small-loading-img"
                  alt="loader"
                />
              </div>
            ) : (
              <div>
                Generate Email
                <FaEnvelopeOpen className="mb-2 ms-2" />
              </div>
            )}
          </Button>
          <a
            href={`mailto:${recipient}?subject=${subject}&body=${encodeMailtoBody(
              matter
            )}`}
            className="btn btn-orange w-100 rounded-0 mt-3"
            onChange={handleSendMailButton}
          >
            Send Email
            <FaPaperPlane className="mb-2 ms-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainCard;
