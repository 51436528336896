import { FaCircleInfo, FaUser, FaUserLock } from "react-icons/fa6";
import "./navbar.css";
import { useNavigate } from "react-router-dom";

const NavbarComponent = ({ isLoggedIn = false, username = "" }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg bg-orange">
          <div className="container">
            <a className="navbar-brand" href="/">
              Intassis
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                {/* <li className="nav-item">
                  <a className="nav-link" href="?">
                    Features
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="?">
                    <FaCircleInfo className="me-1  mb-1" /> About us
                  </a>
                </li>
                {isLoggedIn ? (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="!"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaUser className="me-1  mb-1" />
                      {username}
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a className="dropdown-item" href="/profile">
                          Profile
                        </a>
                        <button
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a className="nav-link" href="/login">
                      <FaUserLock className="me-1  mb-1" />
                      Login
                    </a>
                  </li>
                )}

                {/* <li className="nav-item">
                  <a
                    className="nav-link disabled"
                    href="?"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Disabled
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default NavbarComponent;
