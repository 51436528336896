import { useEffect, useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../miscellaneous";


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false)
  const navigate = useNavigate()
  
  useEffect(()=>{
    const FetchDetails = async (token) => {
      try {
        const response = await axios.get(`${BASE_URL}api/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let login_status = response.data.login_status;        
        if (login_status === "logged_in") {
          navigate("/");
        }
      } catch (error) {        
        console.log(error.response.data.error);
      } finally {
        console.log("done");
      }
    };
    FetchDetails(localStorage.getItem("token"));
  },[navigate])

  const LoginUser = async () => {
    setShowLoading(true)
    try {
      const response = await axios.post(`${BASE_URL}api/login`, {
        username: email,
        password: password,
      });
      setErrorStatus("");
      setEmail("");
      setPassword("");      
      localStorage.setItem("token", response.data.access_token);
      navigate("/");
    } catch (error) {
      setErrorStatus(error.response.data.error);
    } finally {
      console.log("done");
      setShowLoading(false)
    }
  };

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginSubmit = () => {    
    setResponseMessage("");
    setErrorStatus("");
    LoginUser();
  };

  return (
    <main>
      <Container fluid="true">
        <Row>
          <Col
            md={9}
            lg={9}
            className="bg-main register-left-section d-none d-sm-block"
          >
            <div>
              <h2 className="text-white ms-5 mt-5">Login to start applying.</h2>
            </div>
            <div className="register-illustration">
              <img
                className="img-fluid"
                src="login-illustration.png"
                alt="signup"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <Row>
              <h2 className="text-center mt-5 text-main">Login</h2>
              <span className="text-center text-danger">{errorStatus}</span>
              <span className="text-center text-success">
                {responseMessage}
              </span>
              <div className="login-panel mt-5">
                <Row>
                  <Col md={1} lg={1}></Col>
                  <Col md={10} lg={10}>
                    <Input
                      type="email"
                      placeholder="Email"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handleEmailInput}
                      value={email}
                    />
                    <Input
                      type="password"
                      placeholder="password"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handlePasswordInput}
                      value={password}
                    />
                    <Button
                      className="btn-main w-100 rounded-0 custom-input"
                      onClick={handleLoginSubmit}
                    >
                      {showLoading ? (
                        <span>
                          Logging In{" "}
                          <img
                            src="circle-1700_128.gif"
                            className="mb-1 ms-2 small-loading-img"
                            alt="loader"
                          />
                        </span>
                      ) : (
                        <span>Login</span>
                      )}
                    </Button>
                    <div className="text-center mt-5">
                      <a href="/register">
                        Not a registered user? Register here
                      </a>
                    </div>
                    <div className="text-center mt-3">
                      <a href="/forgot-password">Forgot Password</a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default LoginPage;
