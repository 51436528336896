import { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../miscellaneous";
import axios from "axios";


const VerifyUser = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const code = query.get("token");
  const [verStatus, setVerStatus] = useState(false);
  const [mainClass, setMainClass] = useState("hidden")

  useEffect(() => {
    setMainClass("hidden")
    const VerifyUser = async () => {
      try {
        const response = await axios.post(`${BASE_URL}api/verify`, {
          code:code
        });
        if(response.status === 200){
            setVerStatus(true);
        }
        
      } catch (error) {
        setVerStatus(false);
        console.log(error.response.data.error);
      } finally {
        console.log("done");
      }
      setMainClass("")
    };
    VerifyUser();
  },[code]);

  return (
    <main>
      <Container fluid="true">
        <Row className={mainClass === "hidden" ? "": "hidden"}>
          <h2 className="text-center mt-5 text-main">Verifying User</h2>
          <div className="verification-image-panel mt-5">
            <div className="img-container">
              <img
                src="load-8778_256.gif"
                className="img-fluid"
                alt="verified-img"
              />
            </div>
          </div>
          <div className="text-center mt-5">
            <a href="/login">Back to login page</a>
          </div>
        </Row>
      </Container>
      <Container fluid="true" className={mainClass}>
        {verStatus ? (
          <Row>
            <h2 className="text-center mt-5 text-main">User Verified</h2>
            <div className="verification-image-panel mt-5">
              <div className="img-container">
                <img
                  src="3891942.jpg"
                  className="img-fluid"
                  alt="verified-img"
                />
              </div>
            </div>
            <div className="text-center mt-5">
              <a href="/login">Back to login page</a>
            </div>
          </Row>
        ) : (
          <Row>
            <h2 className="text-center mt-5 text-main">Verification failed</h2>
            <div className="verification-image-panel mt-5">
              <div className="img-container">
                <img
                  src=" 3582365.jpg"
                  className="img-fluid"
                  alt="verified-img"
                />
              </div>
            </div>
            <div className="text-center mt-5">
              <a href="/login">Back to login page</a>
            </div>
          </Row>
        )}
      </Container>
    </main>
  );
};
export default VerifyUser;
