import { useEffect, useState } from "react";
import MainCard from "../maincard/main-card.component";
import NavbarComponent from "../navbar/navbar.component";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../miscellaneous";


const HomePage = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");
  const navigate = useNavigate();


  useEffect(()=>{
   const FetchDetails = async (token) => {
     try {
       const response = await axios.get(`${BASE_URL}api/home`, {
         headers: {
           Authorization: `Bearer ${token}`,
         },
       });
       let login_status = response.data.login_status;
       setUser(response.data.user)
       if(login_status === "logged_in"){
        setIsLoggedIn(true);
       }
     } catch (error) {
      navigate("/login");
       console.log(error.response.data.error);
     } finally {
       console.log("done");
     }
   };
    FetchDetails(
      localStorage.getItem("token")
    );
  },[isLoggedIn,navigate])
    return (
      <div>
        <header>
          <NavbarComponent isLoggedIn={isLoggedIn} username={user} />
          <MainCard username={user} />
        </header>
      </div>
    );
}

export default HomePage;

