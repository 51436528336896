import { useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import { BASE_URL } from "../miscellaneous";
import axios from "axios";
import { useLocation } from "react-router-dom";


const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false)
  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordInput = (e) => {
    setConfirmPassword(e.target.value);
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const uidb64 = query.get("z");
  const reset_token = query.get("a");


  const ResetUser = async () => {

    setShowLoading(true)

    try {      
      const response = await axios.post(
        `${BASE_URL}api/reset-password/${uidb64}/${reset_token}`,
        {
          new_password: password,
        }
      );
      console.log(response);
      setErrorStatus("");
      setResponseMessage(response.data.success);      
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      setErrorStatus(error.response.data.error);
    } finally {
      console.log("done");
      setShowLoading(false)
    }
  };
  const handleSubmit = () => {
    console.log(password);
    console.log(confirmPassword);
    if (confirmPassword !== password) {
      setErrorStatus("confirm password did not match");
      return;
    }
    if ("" === password) {
      setErrorStatus("password is required");
      return;
    }
    setResponseMessage("");
    setErrorStatus("");
    ResetUser();
  };

  return (
    <main>
      <Container fluid="true">
        <Row>
          <Col
            md={9}
            lg={9}
            className="bg-main register-left-section d-none d-sm-block"
          >
            <div>
              <h2 className="text-white ms-5 mt-5">
                Reset your passwod carefully.
              </h2>
            </div>
            <div className="register-illustration">
              <img className="img-fluid" src="6343836-ai.png" alt="signup" />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <Row>
              <h2 className="text-center mt-5 text-main">Reset Password</h2>
              <span className="text-center text-danger">{errorStatus}</span>
              <span className="text-center text-success">
                {responseMessage}
              </span>
              <div className="login-panel mt-5">
                <Row>
                  <Col md={1} lg={1}></Col>
                  <Col md={10} lg={10}>
                    <Input
                      type="password"
                      placeholder="password"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handlePasswordInput}
                      value={password}
                    />
                    <Input
                      type="password"
                      placeholder="Confirm password"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handleConfirmPasswordInput}
                      value={confirmPassword}
                    />
                    <Button
                      className="btn-main w-100 rounded-0 custom-input"
                      onClick={handleSubmit}
                    >
                      {showLoading ? (
                        <span>
                          Resetting Password{" "}
                          <img
                            src="circle-1700_128.gif"
                            className="mb-1 ms-2 small-loading-img"
                            alt="loader"
                          />
                        </span>
                      ) : (
                        <span>Reset Password</span>
                      )}
                    </Button>
                    <div className="text-center mt-5">
                      <a href="/login">Back to login page</a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ResetPassword;
