import { useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import axios from "axios";
import { BASE_URL } from "../miscellaneous";


const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const RegisterUser = async () => {
    setShowLoading(true)
    try {
      const response = await axios.post(
        `${BASE_URL}api/register`,
        {
          username: email,
          password: password,
        }
      );
      setErrorStatus("");
      setResponseMessage(
        `${response.data.message}. A verification email has been sent to your mailbox.`
      );
      setEmail("");
      setPassword("");
      setConfirmPassword("");      
      
    } catch (error) {
      setErrorStatus(error.response.data.error);
    } finally {
      console.log("done");
      setShowLoading(false);
    }
    
  };

  const handleEmailInput = (e) => {
     setEmail(e.target.value);
  }

  const handlePasswordInput = (e) => {
     setPassword(e.target.value);
  }

  const handleConfirmPasswordInput = (e) => {
    setConfirmPassword(e.target.value);
  };


  const handleRegisterSubmit = () => {
    if(confirmPassword !== password){
      setErrorStatus("confirm password did not match")
      return
    }
    setResponseMessage("");
    setErrorStatus("");
    RegisterUser();
  }

  console.log(BASE_URL);
  return (
    <main>
      <Container fluid="true">
        <Row>
          <Col
            md={9}
            lg={9}
            className="bg-main register-left-section d-none d-sm-block"
          >
            <div>
              <h2 className="text-white ms-4 mt-5">
                Register once, apply everywhere hassle-free!
              </h2>
            </div>
            <div className="register-illustration">
              <img className="img-fluid" src="signup.png" alt="signup" />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <Row>
              <h2 className="text-center mt-5 text-main">Sign up</h2>
              <span className="text-center text-danger">{errorStatus}</span>
              <span className="text-center text-success">
                {responseMessage}
              </span>
              <div className="login-panel mt-5">
                <Row>
                  <Col md={1} lg={1}></Col>
                  <Col md={10} lg={10}>
                    <Input
                      type="email"
                      placeholder="Email"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handleEmailInput}
                      value={email}
                    />
                    <Input
                      type="password"
                      placeholder="password"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handlePasswordInput}
                      value={password}
                    />
                    <Input
                      type="password"
                      placeholder="Confirm password"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handleConfirmPasswordInput}
                      value={confirmPassword}
                    />
                    <Button
                      className="btn-main w-100 rounded-0 custom-input"
                      onClick={handleRegisterSubmit}
                    >
                      {showLoading ? (
                        <span>
                          Signing Up{" "}
                          <img
                            src="circle-1700_128.gif"
                            className="mb-1 ms-2 small-loading-img"
                            alt="loader"
                          />
                        </span>
                      ) : (
                        <span>Signup</span>
                      )}                      
                    </Button>
                    <div className="text-center mt-5">
                      <a href="/login">Already a registered user? login here</a>
                    </div>
                    <div className="text-center mt-5">
                      <a href="/send-verification-code">Verify user</a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default RegisterPage;
