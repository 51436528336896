import { useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import { BASE_URL } from "../miscellaneous";
import axios from "axios";

const SendVerification = () => {
  const [email, setEmail] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };
  const SendVerificationLink = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/email-verification-link`,
        {
          email: email,
        }
      );
      if (response.status) {
        setResponseMessage(
          "An Email has been sent to your inbox with verification link."
        );
        setErrorStatus("");
        setEmail("");
      }
    } catch (error) {
      setErrorStatus(error.response.data.error);
    } finally {
      //   console.log("done");
    }
  };
  const handleSubmit = () => {
    if(email === ""){
        setErrorStatus("Email is required");
    }else{    
    setResponseMessage("");
    SendVerificationLink();
    }
  };

  return (
    <main>
      <Container fluid="true">
        <Row>
          <Col
            md={9}
            lg={9}
            className="bg-main register-left-section d-none d-sm-block"
          >
            <div>
              <h2 className="text-white ms-5 mt-5">
                An email will be sent to your mailbox with verification link.
              </h2>
            </div>
            <div className="register-illustration">
              <img className="img-fluid" src="3293464-ai.png" alt="signup" />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <Row>
              <h2 className="text-center mt-5 text-main">Send verification link</h2>
              <span className="text-center text-danger">{errorStatus}</span>
              <span className="text-center text-success">
                {responseMessage}
              </span>
              <div className="login-panel mt-5">
                <Row>
                  <Col md={1} lg={1}></Col>
                  <Col md={10} lg={10}>
                    <Input
                      type="email"
                      placeholder="Email"
                      className="custom-input mt-4 mb-4 rounded-0 w-100"
                      onChange={handleEmailInput}
                      value={email}
                    />

                    <Button
                      className="btn-main w-100 rounded-0 custom-input"
                      onClick={handleSubmit}
                    >
                      Send verification link
                    </Button>
                    <div className="text-center mt-3">
                      <a href="/login">Back to login page</a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </main>
  );
};
export default SendVerification;
