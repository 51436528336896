import React, { useState } from "react";
import { Input } from "reactstrap";
import { FaCopy, FaPen } from "react-icons/fa6";

const EditableInput = ({
  type = "text",
  label,
  val = "",
  placeholder = "",
  onChange,
  copy = "",
  setVal,
}) => {
  
  const [editMode, setEditMode] = useState(true);
  const [inputValue, setInputValue] = useState(val);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
    setVal(e.target.value);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(copy);
  };
  return (
    <div className="input-box">
      <label className="ms-1 mb-1 mt-3">{label}</label>
      <div className="input-holder">
        <FaCopy className="copy-field" onClick={handleCopy} />
        <Input
          type={type}
          className={`custom-input rounded-0 ${editMode ? "show-input" : ""}`}
          placeholder={`Edit ${placeholder}`}
          value={inputValue}
          onChange={handleChange}
          disabled={editMode}
        />
        <FaPen className="edit-pen" onClick={handleEdit} />
      </div>
    </div>
  );
};

export default EditableInput;
